/*
  Important: it's usually a good practice to use "use" as a prefix
  for composable names (e. g., "useInfiniteScrolling"). In this
  case we used Vue 3's convention for calling hooks: the "on" prefix,
  as in onMounted(), onCreated(), etc.
*/
import debounce from 'lodash/debounce.js'

/**
 * Executes a callback as soon the token changes its value.
 *
 * @param {function} callback The function that is going to be called.
 */
export function onTokenChange(callback: Function) {
  const { $auth } = useNuxtApp()

  watch(
    () => $auth.store.token,
    () => {
      callback()
    },
  )
}

export function onWindowResize(cb, timeout = 100) {
  onMounted(() => {
    window.addEventListener('resize', debounce(cb, timeout))
  })
}

/**
 * Executes a callback as soon as the user's authentication status changes.
 *
 * @param {function} callback The function that is going to be called.
 */
export function onAuthenticated(callback: Function) {
  const { $auth } = useNuxtApp()

  watch(
    () => $auth.store.isAuthenticated,
    () => {
      callback()
    },
  )
}
